




















































































































































































































import ReputationMessageHandlerModuleBuilder
  from "@/includes/logic/Modules/modules/modules/ReputationMessageHandlerModule/ReputationMessageHandlerModuleBuilder";
import { InputSetups } from "@/mixins/input-setups";
import {
  ReputationCalculationModeEnum,
  ReputationModeEnum
} from "@/includes/logic/Modules/modules/modules/ReputationMessageHandlerModule/types";
import AddAdmin from "@/components/AddAdmin/AddAdmin.vue";
import { AddAdminType } from "@/includes/types/AddAdmin/Enums";
import Placeholders from "@/mixins/placeholders/placeholders";
import {
  ComplexReputationCalculationMode, RandomReputationCalculationMode
} from "@/includes/logic/Modules/modules/modules/ReputationMessageHandlerModule/ReputationCalculationMode";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";

import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue';
import { SelectOption } from "piramis-base-components/src/logic/types";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import HighlightAnchor from "piramis-base-components/src/components/HighlightAnchor.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import MultiMessageEditorWithMediaInput
  from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import {
  MediaEditorMode
} from "piramis-base-components/src/components/NewMessageEditors/types";

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator';

@Component({
  computed: {
    ModuleManagerState() {
      return ModuleManagerState
    }
  },
  components: {
    NotAvailableOptionsOverlay,
    AddAdmin,
    ExtendedHelpMessage,
    SelectInput,
    ConfigField,
    SwitchInput,
    NestedContent,
    HighlightAnchor,
    NumberInput,
    MultiMessageEditorWithMediaInput
  },
  data() {
    return {
      ReputationModeEnum,
      AddAdminType,
      ReputationCalculationModeEnum,
      MediaEditorMode,
      ComplexReputationCalculationMode,
      RandomReputationCalculationMode
    }
  }
})
export default class MainReputationSettings extends Mixins(InputSetups, Placeholders, TariffsTagsHelper) {
  @VModel() module!: ReputationMessageHandlerModuleBuilder

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  get reputationModeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t(`reputation_all`),
        value: ReputationModeEnum.All,
      },
      {
        label: this.$t(`reputation_admins`),
        value: ReputationModeEnum.Admins,
      },
      {
        label: this.$t(`reputation_separated`),
        value: ReputationModeEnum.Separated,
      },
    ]
  }

  get reputationCalculationModeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t(`reputation_standart`),
        value: ReputationCalculationModeEnum.Standard,
      },
      {
        label: this.$t(`reputation_complex`),
        value: ReputationCalculationModeEnum.Complex,
      },
      {
        label: this.$t(`reputation_random`),
        value: ReputationCalculationModeEnum.Random,
      },
    ]
  }

  get multiplier() {
    if (this.module.model.params.reputation_calculation_mode instanceof ComplexReputationCalculationMode) {
      return this.module.model.params.reputation_calculation_mode.multiplier
    }
  }

  set multiplier(value: number | undefined) {
    if (value !== undefined && this.module.model.params.reputation_calculation_mode instanceof ComplexReputationCalculationMode) {
      this.module.model.params.reputation_calculation_mode.multiplier = +value.toFixed(1)
    }
  }
}
